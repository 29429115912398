<template>
    <div :class="{'is-invalid': validation_error}">
        <div :class="{
            'input-group': inputFieldsAddon || inputFieldsPrepend,
            'input-group-sm': size==='sm',
            'disabled': true
        }">
            <span
                class="input-group-addon"
                v-if="inputFieldsPrepend"
            >
                {{ inputFieldsPrepend }}
            </span>
            <input
                type="text"
                :value="localValue"
                @input="localValue = $event.target.value; $emit('input', $event.target.value)"
                :placeholder="formatter(placeholder)"
                class="form-control"
                :disabled="disabled"
            />
            <span
                class="input-group-addon"
                v-if="inputFieldsAddon"
            >
                {{ inputFieldsAddon }}
            </span>
        </div>
        <div v-if="validation_error" class="text-danger">
            {{validation_error}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        placeholder: {
            required: false
        },
        formatter: {
            type: Function,
            default: (value) => value
        },
        deformatter: {
            type: Function,
            default: (value) => value
        },
        validator: {
            type: Function,
            default: (value) => ({
                valid: true,
                error: null
            })
        },
        customValidationError: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false
        },
        inputFieldsAddon: {
            type: String
        },
        inputFieldsPrepend: {
            type: String
        },
        size: {
            type: String,
        },
        disabled: {
            required: false,
            default: false
        },
    },
    computed: {
        localValue: {
            get() {
                return this.formatter(this.value)
            },
            set(val) {
                let validation_error;
                let true_value = this.deformatter(val);
                let valid = this.validator(true_value);
                if (this.required && (true_value === "" || true_value === null)) {
                    valid = false;
                    validation_error = this.$t("general:error:value:required");
                } else if (!valid) {
                    if (this.customValidationError) {
                        validation_error = this.customValidationError;
                    } else {
                        validation_error = this.$t("general:error:value:invalid") + " " + val;
                    }
                }

                if (!valid) {
                    this.validation_error = validation_error;
                } else {
                    this.validation_error = null;
                }
                this.$emit("input", true_value);
            }
        },
    },
    data: function () {
        return {
            validation_error: null,
        }
    },
}
</script>

<style>
.is-invalid input.form-control{
    border: 1px solid red;
}
</style>